@tailwind base;
@tailwind components;
@tailwind utilities;


.header {
  top: 0px;
  width: 100%;
  z-index: 1;
  position: fixed;
}

.header.shadow {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.diagonal-background-right {
  height: 100vh;
  transform: rotateZ(45deg) translateY(-110%) translateX(10%);
}

.diagonal-background-left {
  height: 100vh;
  transform: rotateZ(-45deg) translateY(-110%) translateX(-10%);
}

/* Cookies */

.CookieConsent {
  border-radius: 16px;
  margin: 16px;
  max-width: 700px;
  width: calc(100% - 32px) !important;
  box-shadow: 0 0 16px rgb(0, 0, 0, 0.2);
  background: #fff !important;
  color: #000 !important;
}

#rcc-confirm-button {
  background: #000 !important;
  color: #fff !important;
  border-radius: 8px !important;
}
#rcc-decline-button {
  background: #f00;
  color: #fff !important;
  border-radius: 8px !important;
}

#rcc-confirm-button:hover,
#rcc-decline-button:hover {
  opacity: 0.5;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
ul.list {
  padding-left: 1rem;
}

ul.list li:before {
  color: currentColor;
  content: "\2022";
  font-size: .8em;
  margin: 0 0.65rem 0 -1rem;
  font-weight: 400;
}

li {
  line-height: 1.5;
}